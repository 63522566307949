import React, { Component } from 'react'

export class Card30 extends Component {
    render() {
        const { image, title, text, } = this.props;
        return (

            <div className='p-[13px_16px] flex flex-col rounded-[8px] border-[1px]'>
                <img src={image} className='w-full h-[229px] rounded-[8px] max-[550px]:max-h-[229px]' />
                <div className=''>
                    <div className='flex items-start mt-[15px]'>
                        <h3 id='title' className={`font-[500] text-[24px] ${this.props.class}  max-[400px]:text-[20px]`} >
                            {title}
                        </h3>
                    </div>
                    {text ? (<p className='text-[#092668] font-[500] text-[16px] mt-[6px] max-[400px]:text-[15px]'  >
                        {text}
                    </p>
                    ) : null
                    }

                </div>
            </div>
        )
    }
}

export default Card30