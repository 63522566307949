import React, { Component } from 'react'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux'
import { getGraduats, getMainInfo } from '../actions/MainAction'
import SectionHeader from '../components/SectionHeader'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Card13 from '../components/cards/Card13'
import { Helmet } from "react-helmet";
import Phone2Svg from '../components/svg/Phone2Svg'
import WhatsappSvg from '../components/svg/WhatsappSvg'
export class MainPage extends Component {


  componentDidMount() {
    // localStorage'dan dil bilgisi al
    const savedLanguage = localStorage.getItem('language');

    // Eğer kaydedilmiş bir dil varsa, onu kullan
    if (savedLanguage) {
      this.props.getMainInfo(savedLanguage); // savedLanguage'yi lang_id olarak kullan
    } else {
      this.props.getMainInfo(this.props.lang_id); // varsayılan lang_id
    }

    this.props.getGraduats(0.4);
    window.scrollTo(0, 0);
  }

  getLangCode = () => {
    const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
    return langId === '1' ? '' : '/en';
  };
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };



    const { mainInfoData, contentData, graduatsData } = this.props;

    const langCode = this.getLangCode(); // Dinamik dil kodunu al
    // console.log(contentData);

    // console.log(mainInfoData);
    return (
      <div>
        <Helmet>
          <title>{contentData?.main_page_title}</title>
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="CELT Colleges ingilis dili kurslari" />
          <meta property="og:description" content="CELT ingilis dili kurslari İngiltərənin Bakıda ən böyük tədris mərkəzidir. Cambridge English proqramıyla və milli kitablarımızla sizin xidmətinizdəyik." />
          <meta name="description" content={"CELT ingilis dili kurslari İngiltərənin Bakıda ən böyük tədris mərkəzidir. Cambridge English proqramıyla və milli kitablarımızla sizin xidmətinizdəyik."} />
          <meta name="keywords" content={"CELT ingilis dili kursları, İngiltərənin Bakıda ən böyük tədris mərkəzi, Cambridge English proqramı, ingilis dili tədrisi, milli kitablar, CELT Colleges, ingilis dili kursları, Bakı tədris mərkəzi"} />
          <meta property="og:url" content="https://celt.az/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="600" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="CELT Colleges ingilis dili kurslari" />
          <meta name="twitter:description" content="CELT ingilis dili kurslari İngiltərənin Bakıda ən böyük tədris mərkəzidir. Cambridge English proqramıyla və milli kitablarımızla sizin xidmətinizdəyik." />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="7 minutes" />
        </Helmet>

        <div className='container'>

          <div className=' flex items-center justify-center gap-[20px] max-[1000px]:mt-[32px] ml-[17px]'>

            <a href='tel:+994508051112' target='_blank' className='flex gap-[6px] items-center border-[1px] border-[var(--blue)]  rounded-[50px] p-[6px_12px] max-[350px]:p-[4px_10px]'>
              <span className='rounded-[50%] p-[6px] bg-[#E5F0FF]'>
                <Phone2Svg width='16px' height='16px' fill='var(--blue)' />
              </span>
              <span className='text-[15px] text-[#3E3B3B] font-[500] max-[350px]:text-[12px]'>
                {contentData?.card8_call}
              </span>
            </a>

            <a href='https://wa.me/+994508051112' target='_blank' className='flex gap-[6px] items-center border-[1px] border-[var(--blue)]  rounded-[50px] p-[6px_12px] max-[350px]:p-[4px_10px]'>
              <span className='rounded-[50%] p-[6px] bg-[#DCF8C6]'>
                <WhatsappSvg fill='#25D366' width='17px' height='17px' />
              </span>
              <span className='text-[15px] text-[#3E3B3B] font-[500] max-[350px]:text-[12px] '>
                {contentData?.card8_write_message}
              </span>
            </a>


            {/* <a href={`https://wa.me/${this.convertedNumber(phone_number)}`} target='_blank'  className='flex gap-[2px] items-center'></a> */}

          </div>

          <SectionHeader title={contentData?.nav_1} />

          <div className='border-[1px] rounded-[12px] p-[12px] container'>

            <Slider  {...settings}>
              {
                graduatsData?.map((data, i) => {
                  return (
                    <Card13
                      key={i}
                      first_name={data?.first_name}
                      last_name={data?.last_name}
                      company_name={data?.company_name}
                      image_full_url={data?.inst_image?.image_full_url}
                      country_image={data?.country_image}
                      country_name={data?.country_name}
                      user_image={data?.user_image}
                      program_name={data?.program_name}
                      inst_name={data?.inst_name}
                      ranking={data?.ranking}
                      grade={data?.grade}
                      company_id={data?.company_id}

                    />
                  )
                })
              }
            </Slider>

            <div className=' flex justify-center  mt-[12px] ' >
              <Link to={`${langCode}/xaricde-oxuyanlar/`}>
                <button className='text-[var(--blue)] p-[6px_16px]  text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                  {contentData?.btn_view_all}
                </button>
              </Link>
            </div>
          </div>

        </div>

        <section>
          <SectionHeader title={contentData?.sh_schools} />

          <div className=' container grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1' >

            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[0]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[0]?.path ? mainInfoData.filter(x => x.section_id == 1)[0]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[0]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.btn}
              btntype='1'

            />

            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[2]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[2]?.path ? mainInfoData.filter(x => x.section_id == 1)[2]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[2]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.btn}
              btntype='1'

            />

            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[1]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[1]?.path ? mainInfoData.filter(x => x.section_id == 1)[1]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[1]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.btn}
              btntype='1'

            />

          </div>

        </section>

        <section>
          <SectionHeader title={contentData?.sh_preparations} />

          <div className=' container grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1'>

            {
              mainInfoData.filter(x => x.section_id == 2)?.map((data, i) => {
                return (
                  <Card1
                    key={i}
                    image={data?.image_full_url}
                    path={data?.path ? data?.path : `/main-page/${data?.id}`}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    btn={data?.translate?.btn}
                    btntype='1'

                  />
                )
              })
            }

          </div>
        </section>

        <section>
          <SectionHeader title={contentData?.nav_3} />

          <div className=' container grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1'>

            {
              mainInfoData.filter(x => x.section_id == 3)?.map((data, i) => {
                return (

                  <Card1
                    key={i}
                    image={data?.image_full_url}
                    path={data?.path ? data?.path : `/main-page/${data?.id}`}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    btn={data?.translate?.btn}
                    btntype='1'

                  />
                )
              })
            }

          </div>

        </section>

        <section>
          <SectionHeader title={contentData?.sh_other} />

          <div className=' container grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1'>

            {
              mainInfoData.filter(x => x.section_id == 4)?.map((data, i) => {
                return (

                  <Card1
                    key={i}
                    image={data?.image_full_url}
                    path={data?.path ? data?.path : `/main-page/${data?.id}`}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    btn={data?.translate?.btn}
                    btntype='1'

                  />
                )
              })
            }

          </div>
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  mainInfoData: state.Data.mainInfoData,
  lang_id: state.Data.lang_id,
  graduatsData: state.Data.graduatsData,
  contentData: state.Data.contentData,
});
const mapDispatchToProps = { getMainInfo, getGraduats }
export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
