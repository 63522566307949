import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Card1 extends Component {
  state = {
    info: 1,
    rewiews: 1,
  }

  getStr(text) {
    if (text) {
      return text.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitText(text) {
    if (text) {
      if (text.length > 70) {
        return text.substring(0, 65) + "...";
      } else {
        return text;
      }
    }
    return '';
  }

  getsetrtitle(title) {
    if (title) {
      return title.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitTitle(title) {
    if (title) {
      if (title.length > 19) {
        return title.substring(0, 19) + "...";
      } else {
        return title;
      }
    }
    return '';
  }


  getLangCode = () => {
    const langId = localStorage.getItem('language');
    return langId === '1' ? '' : '/en';
  };

  render() {
    const { info, rewiews, cardimg } = this.state;
    const { title, path, text, btn, cardphoto = cardimg, cardsinfo, image, svg = "", price = "", rewiew, student_count, lessons, contentData } = this.props;

    const langCode = this.getLangCode();

    return (
      <div className='p-[13px_16px] flex flex-col justify-between rounded-[8px] border-[1px]'>
        <img src={image} className='w-full h-[229px] rounded-[8px] max-[550px]:max-h-[229px]' />
        <div className=''>
          <div className='flex items-start mt-[15px]'>
            <h3 id='title' className='font-[500] text-[24px] text-[#EE7100]' title={this.getsetrtitle(title)}>
              {this.splitTitle(this.getsetrtitle(title))}
            </h3>
          </div>
          <p className='text-[#092668] font-[500] text-[16px]' title={this.getStr(text)} >
            {this.splitText(this.getStr(text))}
          </p>
        </div>
        {btn ? (
          <Link to={`${langCode}${path}`}>
            <button className='w-full mt-[25px] bg-[#EE7100] rounded-[50px] p-[8px_0px] flex justify-center font-[600] text-[20px] text-[#fff]'>
              {btn}
            </button>
          </Link>
        ) : null
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
});

export default connect(mapStateToProps)(Card1);