import React, { Component } from 'react'

export class ExamSvg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        fill="none"
        viewBox="0 0 48 48"
      >
        <path
          fill={this.props.fill}
          fillRule="evenodd"
          d="M8 42h24a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2Zm24 2H8a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4Z"
          clipRule="evenodd"
        />
        <path
          fill={this.props.fill}
          fillRule="evenodd"
          d="M18 13a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1ZM18 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1ZM18 25a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1ZM18 29a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1ZM10 26v3h3v-3h-3Zm-1-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1ZM15.707 12.293a1 1 0 0 1 0 1.414L11 18.414l-2.707-2.707a1 1 0 1 1 1.414-1.414L11 15.586l3.293-3.293a1 1 0 0 1 1.414 0ZM42 24h-2v15.333l1 1.334 1-1.334V24Zm2 16-3 4-3-4V22h6v18ZM42 17h-2v2h2v-2Zm-2-2h2a2 2 0 0 1 2 2v4h-6v-4a2 2 0 0 1 2-2Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default ExamSvg