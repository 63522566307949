import React, { Component } from 'react'
import LikeSvg from '../svg/LikeSvg'
import { connect } from 'react-redux'
import StudyAbroadPopup from '../StudyAbroadPopup'
import EmailSvg from '../svg/EmailSvg';
import { Link } from 'react-router-dom';

export class FooterCards extends Component {
  getLangCode = () => {
    const langId = localStorage.getItem('language') || '1';
    return langId === '1' ? '' : 'en';
  };
  render() {
    const { contentData, blogtitle, blog, txt } = this.props;
    const langCode = this.getLangCode();
    // console.log(contentData);

    return (
      <div className='  gap-[20px]  w-full bg-[var(--blue)] flex  justify-between items-center rounded-[8px] p-[25px_40px] max-[600px]:flex-col'>

        {/* max-[1200px]:flex-col max-[1200px]:text-center max-[1200px]:p-[25px_100px] max-[900px]:p-[25px_30px] max-[1200px]:w-[85%]  max-[1035px]:items-start 
            max-[750px]:p-[25px] max-[750px]:bottom-[0px] max-[750px]:mt-[20px] mb-[32px] */}

        {txt === '1' ? (
          <div className='flex justify-center'>
            <span className='font-[600] text-[28px] text-center text-[#fff] leading-[31px] max-[750px]:text-[21px]'>
              {contentData?.compony_meet}
            </span>
          </div>
        ) : null}


        {
          txt === '2' ? (
            <div className='flex justify-center'>
              <span className='font-[600] text-[28px] text-center text-[#fff] leading-[31px] max-[750px]:text-[21px]'>
                {contentData?.international_contact}
              </span>
            </div>
          ) : null
        }


        {blog === "1" ? (
          <div className='flex items-center gap-[22px] '>
            <Link to={`${langCode}/contactus`} className='flex items-center justify-center bg-[#fff]  py-[15px] w-[230px] rounded-[50px] gap-[4px] max-[320px]:w-[200px]'>
              <span className='mt-[4px] text-[17px] font-[600] text-[var(--blue)] max-[350px]:text-[14px] '>{contentData?.make_an_appointment}</span>
              <LikeSvg width='20' height='20' fill='var(--blue)' />
            </Link>
          </div>
        ) : null

        }

        {blog === "2" ? (
          <div className='flex items-center gap-[22px] '>
            <a href="mailto:ahmad@celt.az" target='__blank' className='flex items-end justify-center bg-[#fff]  py-[15px] w-[230px] rounded-[50px] gap-[4px] max-[320px]:w-[200px]'>
              <EmailSvg />
              <span className='mt-[4px] text-[17px] font-[600] text-[var(--blue)] max-[350px]:text-[14px] '>
                ahmad@celt.az
              </span>
            </a>
          </div>
        ) : null

        }

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
})
export default connect(mapStateToProps)(FooterCards)