import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectionHeader from '../components/SectionHeader'
import img1212 from '../images/1212.webp'
import testimage from '../images/tftftf.avif'
import CertificateSvg from '../components/svg/CertificateSvg'
import ExamSvg from '../components/svg/ExamSvg'
import GraduationSvg from '../components/svg/GraduationSvg'
import FooterCards from '../components/footer/FooterCards'
import Card30 from '../components/cards/Card30'
import ielts from '../images/ielts11.webp'
import generalenglish from '../images/general-english.webp'
import teofl from '../images/toefl.webp'
import sat from '../images/sat.webp'


export class InternationalStudentPage extends Component {

    render() {

        const { contentData } = this.props;
        // console.log(contentData);

        return (
            <div className='container'>
                <SectionHeader title={contentData?.international_student} />



                <section className=''>
                    {/* <p className='text-[#828c8a] text-[17px] font-[400] leading-[30px]'>
                        LIFE IN AZERBAIJAN..STUDENT SUPPORT AND INTERNATIONAL STUDENTS GUIDE...
                    </p> */}
                    <p className='text-[#828c8a] text-[17px] font-[400] leading-[30px] text-center'>
                        {contentData?.international_desc}
                    </p>
                    {/* <p className='text-[#828c8a] text-[17px] font-[400] leading-[30px]'>
                        English courses we offer are A1, A2, B1 B2 , C1 IELTS TOEFL AND SAT AND MANY MORE
                    </p> */}
                </section>




                <section className='pt-[32px]'>
                    <div className='grid grid-cols-4  max-[1200px]:grid-cols-2 max-[530px]:grid-cols-1'>
                        <div className='bg-[var(--orange)] p-[20px] flex items-center gap-[12px] rounded-[8px_0px_0px_8px] max-[1200px]:rounded-[8px_0px_0px_0px] max-[530px]:rounded-[8px_8px_0px_0px]'>
                            <CertificateSvg />
                            <h2 className='font-[600] text-[20px] text-[#fff] max-[380px]:text-[17px]'>
                                {contentData?.international_certificate}
                            </h2>
                        </div>
                        <div className='bg-[var(--blue)] p-[20px] flex items-center gap-[12px] max-[1200px]:rounded-[0px_8px_0px_0px] max-[530px]:rounded-[0px_0px_0px_0px]'>
                            <ExamSvg width="32" height="32" fill="#fff" />
                            <h2 className='font-[600] text-[20px] text-[#fff] max-[380px]:text-[17px]'>
                                {contentData?.international_deegre}
                            </h2>
                        </div>
                        <div className='bg-[red] p-[20px] flex items-center gap-[12px] max-[1200px]:rounded-[0px_0px_0px_8px] max-[530px]:rounded-[0px_0px_0px_0px]'>
                            <GraduationSvg width="40" height="40" fill="#fff" />
                            <h2 className='font-[600] text-[20px] text-[#fff] max-[380px]:text-[17px]'>
                                {contentData?.international_pathways}
                            </h2>
                        </div>
                        <div className='bg-[green] p-[20px] flex items-center gap-[12px] rounded-[0px_8px_8px_0px] max-[1200px]:rounded-[0px_0px_8px_0px] max-[530px]:rounded-[0px_0px_8px_8px]'>
                            <CertificateSvg />

                            <h2 className='font-[600] text-[20px] text-[#fff] max-[380px]:text-[17px]'>
                                {contentData?.english_courses}
                            </h2>
                        </div>
                    </div>

                </section>

                <section className='pt-[32px]'>
                    <div className='grid grid-cols-4 gap-[20px] max-[1100px]:grid-cols-3 max-[850px]:grid-cols-2 max-[550px]:grid-cols-1'>

                        <div className='  flex flex-col rounded-[8px] border-[1px]'>
                            <img src={generalenglish} className='w-full h-[auto] rounded-[8px_8px_0px_0px] max-[550px]:max-h-[229px]' />
                            <div className=''>
                                <div className='flex items-start justify-center mt-[10px] '>
                                    <h3 id='title' className={`font-[500] text-[24px] text-[var(--blue)] max-[400px]:text-[20px]`} >
                                        {contentData?.popup_general_ingilsh}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='  flex flex-col rounded-[8px] border-[1px]'>
                            <img src={ielts} className='w-full h-[autp] rounded-[8px_8px_0px_0px] max-[550px]:max-h-[229px]' />
                            <div className=''>
                                <div className='flex items-start justify-center mt-[10px] '>
                                    <h3 id='title' className={`font-[500] text-[24px] text-[var(--blue)] max-[400px]:text-[20px]`} >
                                        IELTS
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='   flex flex-col rounded-[8px] border-[1px]'>
                            <img src={teofl} className='w-full h-[auto] rounded-[8px_8px_0px_0px] max-[550px]:max-h-[229px]' />
                            <div className=''>
                                <div className='flex items-start justify-center mt-[10px] '>
                                    <h3 id='title' className={`font-[500] text-[20px] text-[var(--blue)] max-[400px]:text-[20px]`} >
                                        TOEFL
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className=' flex flex-col rounded-[8px] border-[1px]'>
                            <img src={sat} className='w-full h-[auto] rounded-[8px_8px_0px_0px] max-[550px]:max-h-[229px]' />
                            <div className=''>
                                <div className='flex items-start justify-center mt-[10px] '>
                                    <h3 id='title' className={`font-[500] text-[24px] text-[var(--blue)] max-[400px]:text-[20px]`} >
                                        SAT
                                    </h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


                <section className='pt-[32px]'>
                    <div className='grid grid-cols-2 gap-[32px] max-[650px]:grid-cols-1'>
                        <Card30
                            image={img1212}
                            title={contentData?.international_card1_title}
                            text={contentData?.international_card1_desc}
                            class="text-[#EE7100]"
                        />
                        <Card30
                            image={testimage}
                            title={contentData?.international_card2_title}
                            text={contentData?.international_card2_desc}
                            class="text-[#EE7100]"
                        />

                        {/* <div className='flex flex-col justify-between rounded-[8px] border-[1px]'>
                            <img src={img1212} className='w-full h-[229px] rounded-[8px] max-[550px]:max-h-[229px]' />
                            <div className=''>
                                <div className='flex items-start mt-[15px]'>
                                    <h3 id='title' className='font-[500] text-[24px] text-[#EE7100]' >
                                        how to apply
                                    </h3>
                                </div>
                                <p className='text-[#092668]  font-[500] text-[16px]'  >
                                    Be supported as you learn with a range of international student scholarships. Check the available options, eligibility and application closing dates.
                                </p>
                            </div>
                        </div> */}

                    </div>
                </section>


                <div className='pt-[32px]'>
                    <FooterCards blog="2" txt="2" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
})

export default connect(mapStateToProps)(InternationalStudentPage)