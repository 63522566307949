import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { getContent } from './actions/MainAction'
import MainPage from './pages/MainPage'
import Footer from './components/footer/Footer'
import GraduatedStudents from './pages/GraduatedStudents'
import LanguagePreparation from './pages/LanguagePreparation'
import StudyAbroad from './pages/StudyAbroad'
import KidsEnglish from './pages/KidsEnglish'
import Exams from './pages/Exams'
import Contacts from './pages/Contacts'
import SingleBranch from './pages/SingleBranch'
import SingleExams from './pages/SingleExams'
import NavBar from './components/header/NavBar'
import Blog from './pages/Blog'
import SingleBlogs from './pages/SingleBlogs'
import SingleSchools from './pages/SingleSchools'
import StudyGradeatedStudents from './pages/StudyGradeatedStudents'
import SummerCamp from './pages/SummerCamp'
import GalleryModal from './components/GalleryModal'
import SingleCountries from './pages/SingleCountries'
import SingleUniversity from './pages/SingleUniversity'
import ScrollToTop from './components/ScrollToTop';
import MainSingleExams from './pages/MainSingleExams'
import AllSchools from './pages/AllSchools'
import SuccessPopup from './components/SuccessPopup'
import Loadin from './components/Loadin'
import StudyCenters from './pages/StudyCenters'
import ErorPage from './pages/ErorPage'
import InternationalStudentPage from './pages/InternationalStudentPage'

export class App extends Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.props.getContent(savedLanguage);
    } else {
      this.props.getContent(this.props.lang_id);
    }
  }

  getLangCode = () => {
    const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
    return langId === '1' ? '' : '/en';
  };


  render() {
    const { contentData, galleryModalData, popUpVisible, loader } = this.props;
    const langCode = this.getLangCode(); // Dinamik dil kodunu al
    return (
      <div>
        <BrowserRouter>
          <NavBar />
          {/* <Sidebar /> */}
          <ScrollToTop>
            <Routes>
              <Route path={`${langCode}/`} element={<MainPage />} />
              <Route path={`${langCode}/xaricde-oxuyanlar/`} element={<GraduatedStudents />} />
              <Route path={`${langCode}/ingilis-dili-kursu/`} element={<LanguagePreparation />} />
              <Route path={`${langCode}/xaricde-tehsil/`} element={<StudyAbroad />} />
              <Route path={`${langCode}/qebul-olmus-telebeler`} element={<StudyGradeatedStudents />} />
              <Route path={`${langCode}/xaricde-tehsil/:id`} element={<SingleCountries />} />
              <Route path={`${langCode}/university/:id`} element={<SingleUniversity />} />
              <Route path={`${langCode}/kids`} element={<KidsEnglish />} />
              <Route path={`${langCode}/main-page/:id`} element={<MainSingleExams />} />
              <Route path={`${langCode}/xaricde-tehsil-merkezlerimiz`} element={<StudyCenters />} />
              <Route path={`${langCode}/international-student`} element={<InternationalStudentPage />} />


              <Route path={`${langCode}/summer-school/`} element={<SummerCamp />} />

              <Route path={`${langCode}/muhasibatliq`} element={<MainSingleExams params={{ id: 23 }} />} />
              <Route path={`${langCode}/beynalxalq-muellim-sertifikati`} element={<MainSingleExams params={{ id: 32 }} />} />
              {/* <Route path={` ${langCode} /online-ingilis-dili-kursu' element={<MainSingleExams params={{ id: 20 }} />} /> */}


              <Route path={`${langCode}/ielts-imtahani`} element={<SingleExams params={{ id: 1 }} />} />
              <Route path={`${langCode}/toefl-imtahani`} element={<SingleExams params={{ id: 2 }} />} />
              <Route path={`${langCode}/sat-qebul-imtahani`} element={<SingleExams params={{ id: 3 }} />} />
              <Route path={`${langCode}/gre-imtahani`} element={<SingleExams params={{ id: 4 }} />} />
              <Route path={`${langCode}/duolingo-imtahani`} element={<SingleExams params={{ id: 6 }} />} />
              <Route path={`${langCode}/gmat-imtahani`} element={<SingleExams params={{ id: 9 }} />} />

              <Route path={`${langCode}/ingilis-dili-kurslari`} element={<SingleExams params={{ id: 11 }} />} />
              <Route path={`${langCode}/online-ingilis-dili`} element={<SingleExams params={{ id: 13 }} />} />
              <Route path={`${langCode}/crash-ingilis-dili`} element={<SingleExams params={{ id: 14 }} />} />

              <Route path={`${langCode}/exams/:id`} element={<SingleExams />} />
              <Route path={`${langCode}/imtahanlar/`} element={<Exams />} />

              <Route path={`${langCode}/schools`} element={<AllSchools />} />
              <Route path={`${langCode}/private-schools`} element={<SingleSchools schoolType={'1'} />} />
              <Route path={`${langCode}/primary-schools`} element={<SingleSchools schoolType={'2'} />} />
              <Route path={`${langCode}/secondary-schools`} element={<SingleSchools schoolType={'3'} />} />


              <Route path={`${langCode}/contactus/`} element={<Contacts />} />
              <Route path={`${langCode}/celt-khatai/`} element={<SingleBranch params={{ id: 2 }} />} />
              <Route path={`${langCode}/celt-colleges-28-may/`} element={<SingleBranch params={{ id: 4 }} />} />
              <Route path={`${langCode}/celt-colleges-iceri-seher/`} element={<SingleBranch params={{ id: 5 }} />} />
              <Route path={`${langCode}/celt-colleges-sahil-metrosu/`} element={<SingleBranch params={{ id: 6 }} />} />
              <Route path={`${langCode}/celt-colleges-bakixanovda/`} element={<SingleBranch params={{ id: 7 }} />} />
              <Route path={`${langCode}/celt-nizami/`} element={<SingleBranch params={{ id: 8 }} />} />
              <Route path={`${langCode}/celt-colleges-ecemi/`} element={<SingleBranch params={{ id: 9 }} />} />
              <Route path={`${langCode}/celt-colleges-narimanov/`} element={<SingleBranch params={{ id: 10 }} />} />
              <Route path={`${langCode}/celt-colleges-ingilab/`} element={<SingleBranch params={{ id: 11 }} />} />
              <Route path={`${langCode}/celt-colleges-elmler/`} element={<SingleBranch params={{ id: 12 }} />} />
              <Route path={`${langCode}/celt-hazi-aslanov/`} element={<SingleBranch params={{ id: 13 }} />} />
              <Route path={`${langCode}/celt-colleges-sumqayit/`} element={<SingleBranch params={{ id: 14 }} />} />
              <Route path={`${langCode}/celt-high-school-3/`} element={<SingleBranch params={{ id: 23 }} />} />
              <Route path={`${langCode}/celt-colleges-knightsbridge/`} element={<SingleBranch params={{ id: 29 }} />} />
              <Route path={`${langCode}/celt-colleges-ganjlik`} element={<SingleBranch params={{ id: 30 }} />} />
              <Route path={`${langCode}/celt-ag-sheher`} element={<SingleBranch params={{ id: 33 }} />} />
              <Route path={`${langCode}/celt-kids-narimanov-2/`} element={<SingleBranch params={{ id: 17 }} />} />
              <Route path={`${langCode}/celt-kids-elmler-2/`} element={<SingleBranch params={{ id: 18 }} />} />
              <Route path={`${langCode}/celt-kids-sahil/`} element={<SingleBranch params={{ id: 22 }} />} />



              <Route path={`${langCode}/blogs`} element={<Blog />} />
              <Route path={`${langCode}/blog/:path`} element={<SingleBlogs />} />
              <Route path='*' element={<ErorPage />} />


              {/* <Route path='/premium' element={<PremiumGraduets />} /> */}
              {/* <Route path='/single-graduent/:id' element={<SinglePremiumGraduets />} /> */}
            </Routes>
          </ScrollToTop>
          <Footer />
          {/* <SupportSection /> */}
          {galleryModalData?.position ? <GalleryModal /> : null}
          {popUpVisible ? <SuccessPopup /> : null}
          {loader ? <Loadin /> : null}
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  galleryModalData: state.Data.galleryModalData,
  popUpVisible: state.Data.popUpVisible,
  loader: state.Data.loader,
});

const mapDispatchToProps = { getContent };

export default connect(mapStateToProps, mapDispatchToProps)(App);