import React, { Component } from 'react'

import { withHooks } from '../actions/withHooks'
import { getBlogComments, getSingleBlog } from '../actions/MainAction'
import { connect } from 'react-redux'
import Card23 from '../components/cards/Card23'
import Card24 from '../components/cards/Card24'
import SectionHeader from '../components/SectionHeader'
import FooterCards from '../components/footer/FooterCards'
import { Helmet } from 'react-helmet'

export class SingleBlogs extends Component {
  state = {
    blogData: ''
  }
  componentDidMount() {
    const { navigate } = this.props;
    this.props.getSingleBlog(this.props.params.path, navigate)
      .then(resp => {
        if (resp) {
          this.setState({ blogData: resp })
          this.props.getBlogComments(1, resp?.id)
          window.scrollTo(0, 0)
        } else {
          navigate('/error');
        }
      })
      .catch(() => {
        navigate('/error');
      })
  }



  render() {
    const { title, description, date, blogComments, contentData } = this.props;
    const { blogData } = this.state;

    const langCode = localStorage.getItem('langCode') || 'az';
    // console.log(blogData)
    return (
      <div>
        {
          blogData != "" ?
            <Helmet>
              <title>
                {blogData?.title} | CELT Colleges
              </title>
              <meta name="description" content={blogData?.seo_description} />
              <meta name="keywords" content={blogData?.seo_keywords} />
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={`${blogData?.title} | CELT Colleges`} />
              <meta property="og:description" content={blogData?.seo_description} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2022-08-16T14:43:28+00:00" />
              <meta property="og:image" content={blogData?.image_full_url} />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="3 minutes" />
              <link rel="icon" href="/celt-title.jpeg" />
            </Helmet> : null
        }

        <section className='container pt-[52px]'>
          <div className='flex flex-col gap-[20px] mb-[20px] items-center'>

            <div className=' max-[700px]:mb-[20px] mt-[32px] h-auto'>
              <h4 className='text-[38px] text-[#000] font-[600] h-auto max-[600px]:text-[32px] max-[370px]:text-[25px]'>
                {langCode === 'en' ? blogData?.title_en : blogData?.title}
              </h4>
            </div>

            <img className='rounded-[8px] w-full h-[450px]' src={blogData?.image_full_url} />

          </div>

        </section>

        <section className='container'>
          <div className='list-design innertext' dangerouslySetInnerHTML={{ __html: langCode === 'en' ? blogData?.description_en : blogData?.description }}></div>
        </section>

        <div className='container pt-[72px] w-full'>
          <FooterCards blogtitle={blogData?.title} blog="1" txt="1" />
        </div>

        {/* 
        <section className='container '>
          {
            blogComments.length > 0 ?
              <SectionHeader title={contentData?.blog_comment} /> : null
          }


          <div className='flex flex-col gap-[32px]'>

            {blogComments?.map((comment, i) => (
              <div key={i}>
                <Card23
                  user_name={comment?.user_name}
                      date={comment?.date}  comment
                  comment={comment?.content}
                />

                {comment?.reply?.map((reply, j) => (
                  <Card24
                    key={j}
                    reply_name={reply?.user_name}
                    reply_date={reply?.date} comment
                    reply_comment={reply?.content}
                  />
                ))}
              </div>
            ))}

          </div>

        </section> */}

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  blogComments: state.Data.blogComments,
  contentData: state.Data.contentData
});
const mapDispatchToProps = { getSingleBlog, getBlogComments }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleBlogs))



